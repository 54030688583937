import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import { MdOutlineClose as Cross } from "react-icons/md"
import { BsSpeedometer } from "react-icons/bs"
import { AiOutlineLock } from "react-icons/ai"
import Perk from "./Perk"

const PerksModule = () => {
  return (
    <PerksModuleStyles className="section section__padding">
      <StaticImage
        className="perks__image--bg"
        src="../../../static/abstract-building.jpg"
        alt="Perks Module"
        layout="constrained"
      />
      <div className="perks__image--overlay"></div>
      <div className="container container__tight">
        <Perk
          title="The Fastest Front-end"
          content="Performant. Scalable. Secure."
        >
          <BsSpeedometer className={`image`} />
          {/* <StaticImage
            src="../../../static/logos/gatsby-logo.svg"
            alt="Perk Image"
            layout="constrained"
          /> */}
        </Perk>
        <span className="perks__divider">
          <Cross />
        </span>
        <Perk title="Secure backend" content="Reliable. Long-lasting.">
          <AiOutlineLock className={`image`} />
          {/* <StaticImage
            src="../../../static/logos/contentful-logo.svg"
            alt="Perk Image"
            layout="constrained"
          /> */}
        </Perk>
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule
